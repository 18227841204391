import React, { useEffect } from 'react'
import {
    Typography,
    Row,
    Col,
    Divider,
    Button,
    Form,
    Spin
} from 'antd'
import "./fine.css"
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import { baseurl } from '../../components/lib/settings';
import { LoadingOutlined } from "@ant-design/icons";
import Inactivity from '../../components/inactivityTime/InactivityTime';

const { Title } = Typography;

const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid registration!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} As horas devem estar entre ${min} e ${max}",
    },
};

function Fine() {
    Inactivity()

    const navigate = useNavigate()
    const location = useLocation()
    const [form] = Form.useForm();
    const [valueToPay, setValueToPay] = useState(0)
    const [ocupyTimeControl, setOcupyTimeControl] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [price, setPrice] = useState([])
    const timeLimit = new Date() - new Date(location.state.room.updated_at);
    const ocupyTime = new Date(location.state.room.updated_at) - new Date(location.state.room.time);
    console.log("time", timeLimit)
    console.log("Time check", new Date() - new Date(location.state.room.updated_at))
    const loadIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
    useEffect(() => {
        setIsLoading(true)
        if(Math.floor((timeLimit / 1000 / 60 / 60) % 24) == 0){
            setOcupyTimeControl(true)
        }

    },[])
    

    var dict = location.state.room
    console.log("dict do script", dict)
    function openRoomAfterFine() {
        const data = {
            code: dict.room.code,
            is_occupy: false,
            time: null,
            status: "Limpeza",
            fineAmount: valueToPay,
            aditional_time: true,
        }
        navigate("/rooms/payment/methods", {
            state: {
                data: data,
                url: 'v1/Open_Room_by_code',
            },
        });
        // navigate("/rooms/payment/fine/exceed-time", {
        //     state: {
        //         dict
        //     }
        // })
    }

    const getValueToPay = () => {
        console.log("Horas ", Math.floor((timeLimit / 1000 / 60 / 60) % 24))
        console.log("Minutos", Math.floor((timeLimit / 1000 / 60) % 60))
        if (Math.floor((timeLimit / 1000 / 60 / 60) % 24) > 1 ){
            getPriceByTime("2")
        }else{
            getPriceByTime("1")
        }
       
    }
        


    function getPriceByTime(time){
        const p = 0
        baseurl
        .get(`v1/get_time_price_for_room/${time}/`)
        .then((response) => {
            setValueToPay(response.data.price);
            setIsLoading(false);
        })
        .catch((err) => { });
        
    }

    return (
        <div>
            <br /><br />
            {getValueToPay()}
            <br />
            <Row
                type="flex"
                style={{ alignItems: 'center' }}
                justify="center"
                block
            >
                <Col xs={21} xl={18}>
                    <Form
                        form={form}
                        id={"roomID"}
                        name="basic"
                        layout="vertical"
                        onFinish={openRoomAfterFine}
                        validateMessages={validateMessages}
                    >
                        <Form.Item>
                            <div className='general'>
                                <Typography>
                                    <Title align="center" level={5}>Multa por tempo excedido.</Title>
                                </Typography><br />
                                <div className='general_div' >
                                    <div className='atribute'>Número do quarto</div>
                                    <div className='atribute'><b>{location.state.room.room.code}</b></div>
                                </div >
                                <hr className='separator' />
                                <div className='general_div'>
                                    <div className='atribute'>Hora de entrada</div>
                                    <div className='atribute'><b>{moment(new Date(location.state.room.updated_at)).format("HH:mm:ss")}</b></div>
                                </div>
                                <hr className='separator' />
                                <div className='general_div'>
                                    <div className='atribute'>Tempo de ocupação</div>
                                    <div className='atribute'><b>{Math.floor((ocupyTime / 1000 / 60 / 60) % 24)} H </b></div>
                                </div>

                                <hr className='separator' />
                                <div className='general_div'>
                                    <div className='atribute'>Tempo excedido</div>
                                    <div className='atribute'><b>{ocupyTimeControl && <>{Math.floor((timeLimit / 1000 / 60) % 60)} min</> || <>{Math.floor((timeLimit / 1000 / 60 / 60) % 24)} H</>}</b></div>
                                </div>
                                <hr className='separator' />
                                <div className='general_div'>
                                    <div className='atribute'><b>Total a pagar</b></div>
                                    <div className='atribute'><b>{isLoading && <Spin indicator={loadIcon} /> || valueToPay}</b></div>
                                </div>
                            </div>
                        </Form.Item>
                        <br />
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Pagar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default Fine;
