import React, { useEffect, useState } from "react";
import { Card, Col, Row, Modal, message, Statistic, Spin } from "antd";
import moment from "moment";
// import { CountDownHourly, CountDownDaily } from 'count-down-timer-react';
// import 'count-down-timer-react/dist/index.css';
import "./card.css";
import RoomOptions from "../Modal/RoomOptions";
import { baseurl } from "../lib/settings";
import quarto from "../assets/images/rooms/rooms.png";
import Timer from "../clock/conunt-Timer";
import Stopwatch from "../clock/conunt-Timer";
import MakeDeposit from "../Modal/MakeDeposit";
import CountdownTimer from "../clock/countDown";
import { amountFormat } from "../amount/format_amount";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import { SpinStretch } from "../loading/SpinStretch/spinStretch";
import { LoadingOutlined } from "@ant-design/icons";
import Noty from "../notifications/customNotifications";
import Inactivity from "../inactivityTime/InactivityTime";

const { Meta } = Card;

function Cards() {
  Inactivity()

  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [isDepositModal, setIsDepositModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [depositVisiblie, setDepositVisible] = useState(false);
  const [status, setStatus] = useState(false);
  const [aditionalTime, setAdditionalTime] = useState();
  const [rooms, setRooms] = useState([]);
  const [depositConfirmation, setDepositConfirmation] = useState([]);
  const [roomCode, setRoomCode] = useState();
  const [roomsAmount, setRoomsAmount] = useState([]);
  const [guestAmount, setGuestAmount] = useState([]);
  const [attBalance, setAttBalance] = useState([]);
  const [adminstrator, setAdministrator] = useState(false);
  const [valor, setValor] = useState();
  const [cont, setCount] = useState();
  const { Countdown } = Statistic;
  const [getdata, setGetData] = useState(false);
  const [roomAmount, setRoomAmount] = useState(false);
  const [room, setRoom] = useState({});
  const navigate = useNavigate();
  const loadIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setLoading(true);
    let hour = new Date();
    var time =
      hour.getHours() + ":" + hour.getMinutes() + ":" + hour.getSeconds();
    baseurl
      .get("v1/list_all_rooms")
      .then((response) => {
        setRooms(response.data);
        response.data.map((item) => {
          setCount(item.time);
        });
      })
      .catch((err) => { });
    baseurl
      .get("v1/get_balance/")
      .then((response) => {
        if (Array.isArray(response.data)) {
          setRoomsAmount(response.data);
          setAttBalance(response.data)
          console.log("att do balance", response.data)
        } else {
          setAdministrator(true);
          setRoomsAmount(response.data.atendentBalamce);
          setGuestAmount(response.data.guesthouseBalance);
        }

        setLoading(false);
      })
      .catch((err) => { });
      baseurl
      .get("v1/deposit_confirmation")
      .then((response) => {
        setDepositConfirmation(response.data);
        if(response.data.length > 0){
          message.success(
            `${response.data.length} notificações processadas, 
            confira a sua caixa de notificações.`
          );
        }
        
      })
      .catch((err) => { });
    setGetData(false);

    sessionStorage.removeItem("Rooms");
  }, [getdata]);

  if(cardLoading){
    setInterval(() => {
      setGetData(true)           
    }, 60000);
    
  }

  const state = sessionStorage.getItem("page");

  function callDepositModal(item, status) {
    if (status == "admin") {
      // setRoomAmount(item.balances)
      // setIsDepositModal(true);
      // setDepositVisible(true);
    } else if (status == "atendent") {
      setRoomAmount(item.balance);
      setIsDepositModal(true);
      setDepositVisible(true);
    }
  }

  function callModal(id, price, status, room) {
    setRoomCode(id);
    setValor(price);

    if (status == "Ocupado") {
      if(room.aditional_time){
        const timeLimit = new Date() - new Date(room.updated_at);
        if(Math.floor((timeLimit / 1000 / 60) % 60) > 20 || Math.floor((timeLimit / 1000 / 60) % 60) === 20){
          setRoom(room)
        }
      }
      setStatus(true);
      setIsModal(true);
      setVisible(true);
    }
    if (status == "Limpeza") {
      setStatus(true);
      navigate("/rooms/cleaning");
    } else {
      setIsModal(true);
      setVisible(true);
    }
  }

  function onFinish(code) {
    const data = {
      code: code,
      aditional_time: true,
    };
    baseurl.post(`v1/Open_Room_by_code`, data).then((resp) => {
      if (resp.status == 201 || resp.status == 200) {
        setGetData(true);
        setAdditionalTime(true);
      } else {
      }
    });
  }

  function saldo() {
    alert("");
  }

  function currencyFormat(num) {
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "1,");
  }

  function saldoZero() {
    alert("Para depositar precisa ter um saldo maior que 0MT");
  }





  return (
    <div className="site-card-wrapper">
      
      {cardLoading && <Spin indicator={loadIcon} />}
      <br />
      {(loading && <SpinStretch />) || (
        <>
        
          <div className="pricing">
            {(adminstrator && (
              <>
                <>
                  <>
                    <div
                      className="today"
                      onClick={() => callDepositModal("admin")}
                    >
                      D: {amountFormat(parseInt(roomsAmount))}
                    </div>
                    <div className="month">
                      M: {amountFormat(parseInt(guestAmount))}
                    </div>
                  </>
                </>
              </>
            )) || (
                <>
                  <>
                    {attBalance.map((item, index) => {

                      return (
                        <>
                          <div
                            className="today"
                            onClick={() => callDepositModal(item, "atendent")}
                          >
                            D: {amountFormat(parseInt(item.balance))}
                          </div>
                          <div className="month">M: N/A</div>

                        </>
                      )
                    })}
                  </>
                </>
              )}
          </div>

          <main>
            <section class="cards">
              {rooms.map((item, key) => {
                var datenow = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                if (item.time > datenow) {
                  var time = `${moment(item.time).format("HH:mm:ss")}`;
                } else {
                  var time = `${item.time}`;
                }
                let hours = new Date();
                var timeStart = new Date(item.updated_at);
                var timeEnd = new Date(hours);
                var difference = timeEnd - timeStart;

                return (
                  <div
                    class="card"
                    onClick={() =>
                      callModal(item.room.code, item.room.price, item.status, item)
                    }
                  >
                    <div class="card__content">
                      <p class="card__title text--medium">
                        <div className="to">{item.room.code}</div>
                        {/* <div className="to_rightee">{console.log("ACTUALIZADO: ", item.updated_by)}</div> */}
                        <div className="to_rightee"> {item.updated_by != null && <>{item.updated_by['user_name'].slice(0, 3)}</>}</div>
                      </p>

                      {/* <div className="container"><div className="to_left">{item.room.code}</div><div className="to_right">{item.updated_by['user_name'].slice(0, 3)}</div></div> */}
                      <div class="card__info">
                        {/* <p class="text--medium">   {item.is_occupy && <><Countdown style={{ color: "white" }} value={moment.utc().local().hour(hour).minute(minute).seconds(sec)} onFinish={() => onFinish(item.room.code)} /></>}</p> */}
                        <p class="text--medium--timer">
                          {item.is_occupy && (
                            <>
                              {item.aditional_time && <>
                                
                                <Stopwatch 
                                  control={setCardLoading}
                                  millisec={difference} />
                              </> || (
                                  <CountdownTimer
                                  control={item.aditional_time}
                                    sec={item.time}
                                    roomCode={item.room.code}
                                    getD={setCardLoading}
                                  />
                                )}
                            </>
                          )}
                        </p>
                        {/* <p class="text--medium"> {item.is_occupy && <>{item.aditional_time && <Stopwatch millisec={difference} /> || <Countdown style={{ color: "white" }} value={moment.utc().local().hour(hour).minute(minute).seconds(sec)} onFinish={() => onFinish(item.room.code)} />}</>}</p> */}
                        {(item.status == "Limpeza" && (
                          <p class="card__price text--medium">
                            {(item.is_occupy && (
                              <p className="ocupado">{item.status}</p>
                            )) || <p className="limpeza">{item.status}</p>}
                          </p>
                        )) || (
                            <p class="card__price text--medium">
                              {(item.is_occupy && (
                                <p className="ocupado">{item.status}</p>
                              )) || <p className="disponivel">{item.status}</p>}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </section>
          </main>
          {isModal && (
            <RoomOptions
              visible={visible}
              setVisible={setVisible}
              code={roomCode}
              valor={valor}
              status={status}
              room={room}
              sethandleData={setGetData}
            />
          )}
          {isDepositModal && (
            <MakeDeposit
              visible={depositVisiblie}
              setVisible={setDepositVisible}
              roomAmount={roomAmount}
              sethandleData={setGetData}
            />
          )}
        </>
      )}
      {depositConfirmation.length > 0 && 
        <><Noty width={"30px"} color={"#122C34"} count={depositConfirmation.length} updateData={setGetData} /></>
      }
      
    </div>
  );
}

export default Cards;
// coomit