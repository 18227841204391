import React from "react";
import { Routes, Route } from "react-router";
import Room from "./pages/Rooms/Room"
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Card from "./components/Card/Cards";
import RoomOptions from "./components/Modal/RoomOptions";
import Occupy from "./pages/Occupy/Occupy";
import Restaurant from "./pages/Restaurant/Restaurant";
import Maintenance from "./pages/Maintenance/Maintenance";
import Login from "./pages/Login/Login";
import UserAuth from "./pages/UsersAuth/UserAuth";
import ClientEvaluation from "./pages/Avaliation/ClientEvaluation"
import Footer from "./components/Footer/Footer";
import Cleaning from "./pages/Maintenance/Cleaning";
import Option from "./pages/Maintenance/Options";
import MakeDeposit from "./components/Modal/MakeDeposit";
import Manutencao from "./pages/Maintenance/Manutencao";
import Payment from "./pages/Payment/Payment";
import ListClientAvaliation from "./pages/Avaliation/ListClientAvaliation";
import ListCompletedClientAvaliation from "./pages/Avaliation/ListCompletedClientAvaliation";
import EmployeeBalance from "./pages/Payment/EmployeeBalance";
import ListMaintenance from "./pages/Maintenance/ListMaintenance";
import Fine from "./pages/Fine/Fine";
import FinePayment from "./pages/Fine/FinePayment";
import InactivityTime from "./components/inactivityTime/InactivityTime";
import useTimeout from "./components/inactivityTime/useTimeout";
import Inactivity from "./components/inactivityTime/InactivityTime";
import BasicReports from "./pages/Reports/basicReports";
import BarChart from "./components/Chart/BarChart";
import LiquidPlot from "./components/Chart/LiquidPlot";
import LineChart from "./components/Chart/LineChart";
import HBarChart from "./components/Chart/HBarChart";



function App() {

  return (
    <>
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route path="/" element={<ClientEvaluation />} />
          <Route path="/login" element={<Login />} />
          <Route path="/rooms/main" element={<Room />} />
          <Route path="/rooms/occupy" element={<Occupy />} />
          <Route path="/rooms/restaurant" element={<Restaurant />} />
          <Route path="/rooms/cleaning" element={<Cleaning />} />
          <Route path="/rooms/options" element={<Option />} />
          <Route path="/rooms/maintenance" element={<Maintenance />} />
          <Route path="/rooms/modal" element={<MakeDeposit />} />
          <Route path="/rooms/management/maintenance" element={<Manutencao />} />
          <Route path="/rooms/payment/methods" element={<Payment />} />
          <Route path="/rooms/maintenance" element={<Manutencao />} />
          <Route path="/users/auth" element={<UserAuth />} />
          <Route path="/rooms/maintenance/client/classification" element={<ListClientAvaliation />} />
          <Route path="/rooms/client/completed/classification" element={<ListCompletedClientAvaliation />} />
          <Route path="/rooms/payment/balance" element={<EmployeeBalance />} />
          <Route path="/rooms/maintenance/list" element={<ListMaintenance />} />
          <Route path="/rooms/fine/exceed-time" element={<Fine />} />
          <Route path="/rooms/payment/fine/exceed-time" element={<FinePayment />} />
          <Route path="/rooms/admin/reports" element={<BasicReports />} />
          <Route path="/chart/bar" element={<BarChart />} />
          <Route path="/chart/liquid" element={<LiquidPlot />} />
          <Route path="/chart/line" element={<LineChart />} />
          <Route path="/chart/hbar" element={<HBarChart />} />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </>
  );
}


export default App;