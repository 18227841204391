import React from 'react'
import {
    Modal,
    Button,
    InputNumber,
    Row,
    Col,
    Space,
    Select
} from 'antd'
import { useState } from 'react'
import { baseurl } from '../lib/settings';
import { useEffect } from 'react';

const { Option } = Select


function MakeDeposit(props) {

    const [loading, setLoading] = useState(false);
    const [isvisible, setIsVisible] = useState(false);
    const [depositAmount, setDeposit] = useState(false);
    const [managers, setManagers] = useState([]);
    const [managerID, setManagerID] = useState()
    const { visible, setVisible, onCancel, code, roomAmount, status, sethandleData } = props;

    const showModal = () => {
        setVisible(true);
    }


    useEffect(() => {
        baseurl.get('v1/list_all_managers').then((response) => {
            setManagers(response.data)
            console.log("lista dos managers", response.data)
        })
    }, [])

    const handleOk = () => {
        // setLoading(true);
        const data = {
            totalRoomAmount: roomAmount,
            totalDeposit: depositAmount,
            manager: managerID,
            attendant: 1,
            isDeposit: true,
        }

        baseurl.post(`v1/make_deposit`, data).then((resp) => {
            if (resp.status == 201 || resp.status == 200) {
                setVisible(false);
                sethandleData(true);
                alert("Depósito efectuado com sucesso!");

                // setLoading(true);
            } else {
            }
        })
        // window.location.reload(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onChange = (value) => {
        setDeposit(value)

    }

    const handleSelect = (value) => {
        managers.map((item, key) => {
            if (value == item.user.user_name) {
                setManagerID(item.user.id)
                console.log("values do value", value)
                console.log("values do id", item.user.id)
            } else {
                console.log("algo correu mal")
            }
        })
    };


    return (
        <>
            {/* <Button type="primary" onClick={showModal}>
                Deposit
            </Button> */}
            <Modal
                visible={visible}
                title={`O seu valor acumulado é: ${roomAmount} MT`}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    // <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    //     Submit
                    // </Button>,
                    <Button
                        key="link"
                        // href="https://google.com"
                        type="primary"
                        loading={loading}
                        onClick={handleOk}
                    >
                        Depositar
                    </Button>,
                ]}
            >
                <Space>
                    Valor a depositar &nbsp;&nbsp;<InputNumber size="large" min={1} max={100000} onChange={onChange} />
                </Space><br />
                <br />Pessoa a receber:  &nbsp;&nbsp;&nbsp;&nbsp;
                <Select
                    placeholder="Nome"
                    style={{
                        width: 90,
                    }}
                    onChange={handleSelect}
                >
                    {managers.map((item, key) => {
                        // return <Option value={item.time}>{item.time}</Option>
                        return <Option value={item.user.user_name}>{item.user.user_name}</Option>
                    })}

                </Select>
            </Modal>
        </>
    )
}

export default MakeDeposit