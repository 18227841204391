import { useIdleTimer } from 'react-idle-timer';

export default function Inactivity() {

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    window.location.replace("/users/auth");
    console.log("users auth")
  }

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
    console.log("Actividade")
  }

  const onAction = (event) => {
    // Do something when a user triggers a watched event
    console.log("Accao")
  }



  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime
  } = useIdleTimer({

    onIdle,
    onActive,
    onAction,
    timeout: 15000,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  })

}